<template>
  <!-- 新增企业 -->
  <div>
    <!-- form 表单 -->
    <div class="from">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="120px"
      >
        <el-form-item label="公司名称" prop="name">
          <el-input
            v-model="ruleForm.name"
            placeholder="请输入公司名称"
            style="width: 500px"
          ></el-input>
        </el-form-item>
        <el-form-item label="企业登录账号" prop="account">
          <el-input
            v-model="ruleForm.account"
            placeholder=""
            style="width: 500px"
          ></el-input>
        </el-form-item>
        <el-form-item label="企业登录密码" prop="pwd">
          <el-input
            v-model="ruleForm.pwd"
            show-password
            placeholder=""
            style="width: 500px"
          ></el-input>
        </el-form-item>
        <el-form-item label="对应通证" prop="certificateId">
          <el-select
            v-model="ruleForm.certificateId"
            placeholder="请选择对应通证"
            style="width: 500px"
          >
            <el-option
              v-for="(item, index) in selector"
              :key="index"
              :label="item.tokenName"
              :value="item.certificateId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="通证数量" prop="tokenNumber">
          <el-input
            v-model="ruleForm.tokenNumber"
            placeholder="请输入通证数量"
            onkeyup="this.value=this.value.replace(/[^\d]/g,'');"
            style="width: 500px"
          ></el-input>
        </el-form-item>
        <el-form-item label="所属行业" prop="trade">
          <el-input
            v-model="ruleForm.trade"
            placeholder="请输入所属行业"
            style="width: 500px"
          ></el-input>
        </el-form-item>
        <el-form-item label="注册资本" prop="registeredFund">
          <el-input
            v-model="ruleForm.registeredFund"
            placeholder="请输入注册资本"
            style="width: 500px"
          ></el-input>
        </el-form-item>
        <el-form-item label="公司地址" prop="address">
          <el-input
            v-model="ruleForm.address"
            placeholder="请输入公司地址"
            style="width: 500px"
          ></el-input>
        </el-form-item>
        <el-form-item label="公司网站" prop="website">
          <el-input
            v-model="ruleForm.website"
            placeholder="请输入公司网站"
            style="width: 500px"
          ></el-input>
        </el-form-item>
        <el-form-item label="归集地址" prop="walletArea">
          <el-input
            v-model="ruleForm.walletArea"
            placeholder="请输入归集账户钱包地址"
            style="width: 500px"
          ></el-input>
        </el-form-item>
        <el-form-item label="通知地址" prop="notifyUrl">
          <el-input
            v-model="ruleForm.notifyUrl"
            placeholder="请输入通知地址"
            style="width: 500px"
          ></el-input>
        </el-form-item>
        <el-form-item label="公司邮箱" prop="email">
          <el-input
            v-model="ruleForm.email"
            placeholder="请输入公司邮箱"
            style="width: 500px"
          ></el-input>
        </el-form-item>
        <el-form-item label="人员规模" prop="staffSize">
          <el-input
            v-model="ruleForm.staffSize"
            placeholder="请输入人员规模"
            style="width: 500px"
          ></el-input>
        </el-form-item>
        <el-form-item label="公众号" prop="officialAccounts">
          <el-input
            v-model="ruleForm.officialAccounts"
            placeholder="请输入公众号"
            style="width: 500px"
          ></el-input>
        </el-form-item>
        <el-form-item label="主营业务" prop="business">
          <el-input
            type="textarea"
            :rows="4"
            v-model="ruleForm.business"
            placeholder="请输入主营业务"
            style="width: 500px"
          ></el-input>
        </el-form-item>
        <el-form-item label="公司介绍" prop="introduce">
          <el-input
            type="textarea"
            :rows="4"
            v-model="ruleForm.introduce"
            placeholder="请输入公司介绍"
            style="width: 500px"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="info" @click="getBack">返回</el-button>
          <el-button type="primary" @click="submitForm('ruleForm')"
            >新增</el-button
          >
          <el-button type="" @click="resetForm('ruleForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ruleForm: {
        address: "",
        business: "",
        certificateId: "",
        email: "",
        introduce: "",
        name: "",
        officialAccounts: "",
        registeredFund: "",
        staffSize: "",
        notifyUrl:'',
        trade: "",
        website: "",
        walletArea: "",//归集账户钱包地址
        account: "", //企业账号
        pwd: "", // 企业登录密码
        tokenNumber: "", // 通证数量
      },
      rules: {
        name: [{ required: true, message: "请输入公司名称", trigger: "blur" }],
        pwd: [{ required: true, message: "请输入密码", trigger: "blur" }],
        account: [
          { required: true, message: "请输入企业账号", trigger: "blur" },
        ],
        certificateId: [
          { required: true, message: "请选择对应通证", trigger: "blur" },
        ],
        business: [
          { required: true, message: "请输入主营业务", trigger: "blur" },
        ],
        email: [{ required: true, message: "请输入公司邮箱", trigger: "blur" }],
        introduce: [
          { required: true, message: "请输入公司简介", trigger: "blur" },
        ],
        address: [
          { required: true, message: "请输入公司地址", trigger: "blur" },
        ],
        officialAccounts: [
          { required: true, message: "请输入公司公众号", trigger: "blur" },
        ],
        registeredFund: [
          { required: true, message: "请输入注册资金", trigger: "blur" },
        ],
        staffSize: [
          { required: true, message: "请输入公司人员规模", trigger: "blur" },
        ],
        trade: [
          { required: true, message: "请输入公司所属行业", trigger: "blur" },
        ],
        website: [
          { required: true, message: "请输入公司网站", trigger: "blur" },
        ],
        walletArea: [
          { required: true, message: "请输入归集账户钱包地址", trigger: "blur" },
        ],
        notifyUrl: [
          { required: true, message: "请输入通知地址", trigger: "blur" },
        ],
        tokenNumber: [
          { required: true, message: "请输入通证数量", trigger: "blur" },
        ],
      },
      selector: null,
    };
  },
  created() {
    // 获取数字通证下拉框
    this.axios.get("/admin/certificate/getDropDownBox", {}).then((res) => {
      if (res.data.code == 200) {
        this.selector = res.data.data;
        console.log(this.selector);
      }
    });
  },
  methods: {
    //   返回上一页面
    getBack() {
      this.$router.go(-1);
    },
    // 新增
    submitForm(formName) {
      console.log(JSON.stringify(this.ruleForm));
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // alert("submit!");
          this.axios.post("/admin/firm/addFirm", this.ruleForm).then((res) => {
            console.log(res);
            if (res.data.code == 200) {
              this.$message({
                message: "添加成功",
                type: "success",
              });
              this.resetForm(formName);
              this.$router.go(-1);
            }
            if (res.data.code == 400) {
              this.$message({
                message: res.data.mesg,
                type: "error",
              });
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //   重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<style lang="scss" scoped>
.from {
  padding: 20px;
}
</style>
